<template>
  <Communication></Communication>
</template>
<script>
import Communication from "../../components/Communication.vue";
export default {
  components: {
    Communication,
  },
};
</script>